import React from "react";
import { Icon } from "semantic-ui-react";

export const listPaperPlaneIcon = <Icon.Group className="TreeMenu_normalMenuItem">
    <Icon name='list' />
    <Icon corner name='paper plane' />
</Icon.Group>;

export const listPencilIcon = <Icon.Group className="TreeMenu_normalMenuItem">
    <Icon name='list' />
    <Icon corner name='pencil' />
</Icon.Group>;

export const balancePencilIcon = <Icon.Group className="TreeMenu_normalMenuItem">
    <Icon name='balance' />
    <Icon corner name='pencil' />
</Icon.Group>;