import { EntityFormLightOpenType } from "@crispico/foundation-react/AppMeta";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Drawer } from "antd";
import React, { ReactNode } from "react";
import { Button, Container, Header, Icon, Modal, Segment, SemanticICONS } from "semantic-ui-react";
import { CrudHeader } from "../CrudHeader";
import { EntityDescriptor } from "../EntityDescriptor";
import { EntityEditorFormSimple } from "../EntityEditorFormSimple";

class EntityFormLightState extends State {
    entity = {} as any;
    formOpen = false;
    values = {};
    rowIndex = 0;
}

class EntityFormLightReducers<S extends EntityFormLightState = EntityFormLightState> extends Reducers<S> {
    
    onSubmitHandler(values: any, rowIndex: number) {
        this.s.values = values;
    }

    open(entity: any, rowIndex: number) {
        this.s.formOpen = true
        entity ? this.s.entity = entity : entity = { id: undefined }
        this.s.rowIndex = rowIndex
    }

    onCancel() {
        this.s.formOpen = false;
        this.s.entity = {};
    }
}

type PropsEntityFormLight = {
    entityDescriptor: EntityDescriptor,
    onSubmit: (val: any) => void,
    saveDisabled?: boolean,
    headerContent?: string,
    headerIcon?: SemanticICONS,
    modalClassName?: string,
    entityFormLightOpenType?: EntityFormLightOpenType,
    refEditor?: React.RefObject<any>
} & RRCProps<EntityFormLightState, EntityFormLightReducers>;

export class EntityFormLightRaw extends React.Component<PropsEntityFormLight> {

    open(entity: any, rowIndex: number) {
        this.props.r.open(entity, rowIndex);
    }

    renderEntityEditorFormSimple(): ReactNode {
        return (
            <EntityEditorFormSimple
                ref={this.props.refEditor} entityDescriptor={this.props.entityDescriptor} entity={this.props.s.entity} onSubmitHandler={(values) => {
                    this.props.r.onSubmitHandler(values, this.props.s.rowIndex);
                    let params = { values: values, rowIndex: this.props.s.rowIndex, clientValidationError: false };
                    this.props.onSubmit(params);
                    if (!params.clientValidationError) {
                        this.props.r.onCancel();
                    }
                }} saveDisabled={this.props.saveDisabled}
                onCancelHandler={() => this.props.r.onCancel()} />
        );
    }

    renderHeader(headerIcon: SemanticICONS, headerContent: string): ReactNode {
        return (
            <>
                <CrudHeader
                    content={{
                        component:
                            <Segment className="EntityEditorPage_header_content">
                                <div className="flex-container-row flex-center gap5">
                                    <Icon size="big" name={headerIcon} className="EntityCrudHeader_white" />
                                    <h2 className="EntityCrudHeader_white no-margin">{headerContent}</h2>
                                </div>
                            </Segment >,
                        className: "CrudHeader_editorHeight"
                    }}
                />
            </>
        );
    }

    protected getContainerCssClasses() {
        let noScroll = this.props.entityDescriptor.scrollOnlyContentInEditor;
        if (noScroll === undefined) {
            noScroll = AppMetaTempGlobals.appMetaInstance.scrollOnlyContentInEditor;
        }
        const outer = "EntityEditorPage_container " + (noScroll ? "flex-grow" : " ");
        const inner = "EntityEditorPage_segment " +  (noScroll ? "EntityEditorPage_containerOverflow" : "");
        return { outer: outer , inner: inner }
    }

    render() {
        const cls = this.getContainerCssClasses();
        const headerIcon: SemanticICONS = this.props.headerIcon ? this.props.headerIcon : 'address book';
        const headerContent: string = this.props.headerContent ? this.props.headerContent : this.props.entityDescriptor.name;
        const entityFormLightOpenType: EntityFormLightOpenType = this.props.entityFormLightOpenType !== undefined ? this.props.entityFormLightOpenType : AppMetaTempGlobals.appMetaInstance.entityFormLightOpenType;
        return (
            <>
                {entityFormLightOpenType === EntityFormLightOpenType.MODAL &&
                    <Modal className={"EntityFormLight_modal" + (this.props.modalClassName !== undefined ? " " + this.props.modalClassName : "")}
                        open={this.props.s.formOpen} closeOnDimmerClick={true} onClose={() => this.props.r.onCancel()}>
                        <Header icon={headerIcon} content={headerContent} />
                        <Modal.Content>
                            {this.renderEntityEditorFormSimple()}
                        </Modal.Content>
                    </Modal>
                }
                {entityFormLightOpenType === EntityFormLightOpenType.DRAWER &&
                    <Drawer className="RouteDrawer" visible={this.props.s.formOpen} width="80%" closeIcon={<Button icon="close" circular />}
                        onClose={() => this.props.r.onCancel()} destroyOnClose>
                        <div className="flex-container flex-grow" data-cy="flexContainer">
                            {this.renderHeader(headerIcon, headerContent)}
                            <Container fluid className={cls.outer}>
                                <Segment className={cls.inner}>
                                    {this.renderEntityEditorFormSimple()}
                                </Segment>
                            </Container>
                        </div>
                    </Drawer>
                }
            </>
        );
    }
}

export const EntityFormLight = ReduxReusableComponents.connectRRC(EntityFormLightState, EntityFormLightReducers, EntityFormLightRaw);
"../../AppMeta""../../AppMetaTempGlobals""../../reduxHelpers""../../reduxReusableComponents/ReduxReusableComponents"