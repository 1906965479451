import { ReactElement, ReactNode } from "react";
import React from "react";
import { Table } from "semantic-ui-react";
import { Utils } from "../../utils/Utils";
import { Tree, TreeState, TreeReducers, PropsNotFromState as TreeProps } from "../TreeRRC/Tree";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { TreeMenuReducers, TreeMenuState } from "../treeMenu/TreeMenu";

export type PropsNotFromState = {
    columns: { name: string, label: string, width: number; }[];
    textAlign?: 'center' | 'left' | 'right';
    verticalAlign?: 'bottom' | 'middle' | 'top';
    renderColumn: (object: any, columnName: string) => ReactNode;
};

type TreeTableProps = PropsNotFromState & TreeProps & RRCProps<TreeMenuState, TreeMenuReducers>;

export class TreeTableState extends TreeState { }
export class TreeTableReducers<S extends TreeTableState = TreeTableState> extends TreeReducers<S> { }

// TODO: should have testsAreDemo; I see it extends TreeMenu; I don't think it's OK.
export class TreeTable<T extends TreeTableProps = TreeTableProps> extends Tree<T> {
    protected renderAdditionalColumns(props: any) {
        let cells = [];

        if (this.props.renderColumn !== undefined) {
            const objectId = props.key.split(Utils.defaultIdSeparator);
            const object = Utils.navigate(this.getRoot(), objectId);
            for (let i = 1; i < this.props.columns.length; i++) {
                let newProps = { ...props, style: { background: props.style.background }, key: i };
                if (this.props.renderColumn)
                    cells.push(<Table.Cell textAlign={this.props.textAlign} verticalAlign={this.props.verticalAlign} {...newProps} key={`${object[this.props.columns[i].name]}${i}`}>
                        {this.props.renderColumn ? this.props.renderColumn(object, this.props.columns[i].name) : object[this.props.columns[i].name]}</Table.Cell>);
            }
        }
        return cells;
    }

    protected renderItemWrapperInternal(props: any, ...children: any) {
        let newProps = { ...props, style: { ...props.style, display: "tableCell" } };
        return (
            <Table.Row key={props.key}>
                <Table.Cell {...newProps} verticalAlign={this.props.verticalAlign}>
                    {children}
                </Table.Cell>
                {this.renderAdditionalColumns(props)}
            </Table.Row>
        );
    }

    protected renderHeader() {
        let header = [];
        for (let i = 0; i < this.props.columns.length; i++) {
            header.push(<Table.HeaderCell key={i} width={this.props.columns[i].width as any}>{this.props.columns[i].label}</Table.HeaderCell>);
        }
        return header;
    }

    protected renderMain(mainProps: any, mainChildren: Array<any>): ReactElement {
        return (
            <Table celled {...mainProps}>
                <Table.Header>
                    <Table.Row textAlign="center">
                        {this.renderHeader()}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {mainChildren}
                </Table.Body>
            </Table>
        );
    }
}

export const TreeTableRRC = ReduxReusableComponents.connectRRC(TreeTableState, TreeTableReducers, TreeTable);"../../reduxReusableComponents/ReduxReusableComponents"