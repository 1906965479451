import { sliceColumnConfigDropdown } from "@crispico/foundation-react/components/ColumnConfig/ColumnConfigDropdown";
import { COLUMN_DEFAULT_WIDTH } from "@crispico/foundation-react/components/ColumnConfig/dataStructures";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Cell, CellProps } from "fixed-data-table-2";
import _, { uniqueId } from "lodash";
import moment from "moment";
import { Moment } from "moment";
import React, { ReactElement, ReactNode } from "react";
import { Button, Header, Modal, Menu, SemanticICONS } from "semantic-ui-react";
import { createSliceFoundation, EntityTableSimpleProps, getBaseReducers, PropsFrom, StateFrom, Utils } from "../..";
import { EntityDescriptor, FieldDescriptor } from "../../entity_crud/EntityDescriptor";
import { ColumnDefinition, CONTENT_MENU_COLUMN_WIDTH, EntityTableSimpleRRC, EntityTableSimple, EntityTableSimpleReducers, EntityTableSimpleState, ITableActionParamForRun } from "../../entity_crud/EntityTableSimple";
import { EntityEditorFormSimple } from "../EntityEditorFormSimple";
import { DatePickerFieldEditor } from "../fieldEditors/DatePickerFieldEditor";
import { EntityFormLight, EntityFormLightRaw, } from "./EntityFormLight";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";

export const NEW_ENTITY_INDEX = -1;

export const sliceEntityTableLight = createSliceFoundation(class SliceEntityTableLight {
    initialState = {
        modalOpen: false,
        validationMessage: undefined as unknown as string
    }

    nestedSlices = {
        columnConfigDropdown: sliceColumnConfigDropdown
    }

    reducers = {
        ...getBaseReducers<SliceEntityTableLight>(this),

        onCancel(state: StateFrom<SliceEntityTableLight>) {
            state.modalOpen = false;
        }
    }
})

export type PropsNotFromState = {
    entityDescriptor: EntityDescriptor,
    onSelectItem?: (itemId: any) => void,
    // onSave receives as parameters the list of entities from table alter pressing "save" on editor and the entity that has been added
    onSave?: (entities: any, addedEntity: any) => void,
    // onDelete receives as parameters the list of remaining entities after one entity has been deleted and the entity that has been deleted
    onDelete?: (remainingEntities: any, removedEntityIndex: number) => void,
    onAdd?: (val?: any, getDefaultValuesForFields?: () => { [key: string]: any }) => any
    renderCellCustomizer?: (props: CellProps) => void;
    columns?: ColumnDefinition[],
    // doNotRemoveEntityFromTable and doNotAddEntityToTable was used because there are cases when a verification is needed before deleting or adding a row to table
    actions?: { showAddButton?: boolean, showDeleteButton?: boolean, showEditButton?: boolean, disableAddButton?: boolean, saveFormDisabled?: boolean,
        doNotRemoveEntityFromTable?: boolean, doNotAddEntityToTable?: boolean, additionalMenuItems?: (entity: any) => IAction[] }
    formCustomizer?: { headerContent?: string, headerIcon?: SemanticICONS, modalClassName?: string, customEntityDescriptorForEditor?: EntityDescriptor, showInModal?: boolean, mandatoryFields?: string[], onItemCustomEdit?: (entity: any, rowIndex: number) => void }
    // fieldsToBeCopied: array of fields names used when copying an existing row
    // because usually not all properties should be copied, the properties that should be copied must be specified
    fieldsToBeCopied?: string[],
    getDefaultValuesForFields?: () => { [key: string]: any }
    // this method was added because there are cases when in a table, it should be possible to delete only some specific rows
    getConditionForShowingDelete?: (entity: any) => boolean
    // if this property is not specified (false), the table will initially render with equal columns widths
    useDefaultColumnsWidths?: boolean
    // this method is used only when the tabel is in a modal (not to be confused with onSave method that is sent to editor and is called every time a row is added/updated)
    modalCustomizer?: { onModalOk?: (entities: any[]) => void, onModalCancel?: () => void, additionalModalContent?: () => JSX.Element },
    "data-testid"?: string
}

export type EntityTableLightProps = PropsFrom<typeof sliceEntityTableLight> & PropsNotFromState;

export class EntityTableLight<P extends EntityTableLightProps = EntityTableLightProps> extends React.Component<P> {

    protected entityTableSimpleCustomizedRef = React.createRef<EntityTableSimpleCustomizedRaw>();
    protected entityFormLightRef = React.createRef<EntityFormLightRaw>();
    protected refEditor = React.createRef<EntityEditorFormSimple>();

    getEntityFormLightRef() {
        return this.entityFormLightRef;
    }

    getEntityTableSimpleCustomizedRef() {
        return this.entityTableSimpleCustomizedRef;
    }

    constructor(props: any) {
        super(props)
        !this.props.columnConfigDropdown.columnConfig && this.props.useDefaultColumnsWidths && this.props.dispatchers.columnConfigDropdown.updateColumnConfig(this.props.entityDescriptor.getDefaultColumnConfig());
    }

    open(entities: any) {
        this.props.dispatchers.setInReduxState({ modalOpen: true });
        this.entityTableSimpleCustomizedRef.current?.setEntities(entities);
    }

    onAddOrUpdate(entity: any, rowIndex: number) {
        if (rowIndex === NEW_ENTITY_INDEX) {
            return this.entityTableSimpleCustomizedRef.current?.addEntity(entity);
        } else {
            return this.entityTableSimpleCustomizedRef.current?.updateEntity(entity, rowIndex);
        }
    }
    
    onDeleteClick() {
        return this.entityTableSimpleCustomizedRef.current?.deleteSelected();
    }

    protected renderDeleteButtons() {
        //doNotRemoveEntityFromTable was used because there are cases when a confirmation is needed before deleting the selected row
        !this.props.actions?.doNotRemoveEntityFromTable && this.onDeleteClick();
        if (this.entityTableSimpleCustomizedRef.current) {
            const removedElementIndex = this.entityTableSimpleCustomizedRef.current?.getSelected();
            const removedElement = this.entityTableSimpleCustomizedRef.current?.getEntities()[removedElementIndex];
            const remaining = this.entityTableSimpleCustomizedRef.current?.getEntities().filter(item => !_.isEqual(item, removedElement));
            this.props.onDelete?.(remaining, removedElementIndex);
        }
    }

    protected onItemEdit = (entity: any, rowIndex: number) => {
        if (this.props.formCustomizer?.onItemCustomEdit) {
            this.props.formCustomizer.onItemCustomEdit(entity, rowIndex);
        } else {
            this.openFormLight(entity, rowIndex);
        }
    }

    protected provideActionsForRow = (actionParam: ITableActionParamForRun): IAction[] => {
        const conditionForShowingDelete = this.props.getConditionForShowingDelete ? this.props.getConditionForShowingDelete(actionParam.selection[0]) : true;
        let contextMenuItems : IAction[] = [
            {
                isVisible: (param) => conditionForShowingDelete,
                icon: "delete",
                label: _msg("entityCrud.table.delete"),
                run: (param) => {
                    this.renderDeleteButtons();
                }
            },
            {
                isVisible: (param) => this.props.actions?.showEditButton !== false,
                icon: "edit",
                label: _msg("entityCrud.table.edit"),
                run: (param) => {
                    // The index is necessary for the form light component
                    let rowIndex = this.entityTableSimpleCustomizedRef.current!.props.s.entities.findIndex((entity, index) => entity.id === param.selection[0].id);
                    this.onItemEdit(param.selection[0], rowIndex);
                }
            },
            {
                isVisible: (param) => this.props.fieldsToBeCopied !== undefined,
                icon: "copy",
                label: _msg("entityCrud.table.copy"),
                run: (param) => {
                    let entity = this.props.onAdd ? this.props.onAdd(this.entityTableSimpleCustomizedRef.current?.getEntities()[0], this.props.getDefaultValuesForFields) : undefined;
                    for (let i = 0; i < this.props.fieldsToBeCopied!.length; i++) {
                        entity = { ...entity, [this.props.fieldsToBeCopied![i]]: param.selection[0][this.props.fieldsToBeCopied![i]] };
                    }
                    // copy works as an "add", initially it does not have a row index, this is why we use NEW_ENTITY_INDEX 
                    this.openFormLight(entity, NEW_ENTITY_INDEX);
                }
            }
        ];
        contextMenuItems = contextMenuItems.concat(this.props.actions && this.props.actions.additionalMenuItems ?
            this.props.actions.additionalMenuItems(actionParam.selection[0]) : []);
        return contextMenuItems;
    }

    protected showValidationMessage() {
        return (
            <ModalExt onClose={() => this.props.dispatchers.setInReduxState({ validationMessage: undefined })}
                open={this.props.validationMessage !== undefined} size='small' severity={Severity.INFO}>
                <Modal.Header>
                    {_msg("general.info")}
                </Modal.Header>
                <Modal.Content>
                    {this.props.validationMessage}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.props.dispatchers.setInReduxState({ validationMessage: undefined })}>
                        {_msg("general.ok")}
                    </Button>
                </Modal.Actions>
            </ModalExt>
        );
    }

    protected openFormLight = (entity: any, rowIndex: number) => {
        this.entityFormLightRef.current!.open(entity, rowIndex);
    }

    protected disableValidFrom = (current: Moment) => {
        const validUntil = this.refEditor.current?.formikContext?.values?.validUntil;
        // Can not select days after toDate
        if (validUntil) {
            return current && current > moment(validUntil);
        }
        return false;
    }

    protected disableValidUntil = (current: Moment): boolean => {
        const validFrom = this.refEditor.current?.formikContext?.values?.validFrom;
        // Can not select days before fromDate
        if (validFrom) {
            return current && current < moment(validFrom);
        }
        return false;
    }

    // For history editor, the user should not be able to select a "validFrom" value that is after the "validUntil" value and vice versa.
    protected disableDatesForHistoryEditor = (entityDescriptor: EntityDescriptor) => {
        if ((this.props as any).isHistoryTable) {
            entityDescriptor.getField("validFrom").additionalFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { ...entityDescriptor.getField("validFrom").additionalFieldEditorProps as any, disabledDate: this.disableValidFrom });
            entityDescriptor.getField("validUntil").additionalFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { ...entityDescriptor.getField("validUntil").additionalFieldEditorProps, disabledDate: this.disableValidUntil });
        }
    }

    protected getMainContent() {
        let editorEntityDescriptor = new EntityDescriptor(this.props.formCustomizer?.customEntityDescriptorForEditor ? this.props.formCustomizer?.customEntityDescriptorForEditor : this.props.entityDescriptor);
        this.disableDatesForHistoryEditor(editorEntityDescriptor);
        return (<>
            {this.showValidationMessage()}
            <div className="EntityTableLight flex-container flex flex-grow" data-testid={this.props["data-testid"]} id={this.props.entityDescriptor.name}>
                <EntityTableSimpleCustomized selectedIsRowIndex={true} id={uniqueId("entityTableSimpleCustomized-")} ref={this.entityTableSimpleCustomizedRef}
                    entityDescriptor={this.props.entityDescriptor} provideActionsForRow={this.provideActionsForRow}
                    columns={this.props.columnConfigDropdown.columnConfig ? this.props.columnConfigDropdown.columnConfig.configObject.columns! : (this.props.columns ? this.props.columns : undefined)}
                    onColumnMoved={(event: any) => this.props.dispatchers.columnConfigDropdown.updateColumnOrder(event)}
                    onColumnResized={(width: number, name: string) => this.props.dispatchers.columnConfigDropdown.updateColumnSize({ width: width, name: name })}
                    onDoubleClickItem={(i, rowIndex) => {
                        if (this.props.actions?.showEditButton !== false) {
                            this.onItemEdit(i, rowIndex);
                        }
                    }}
                    renderCellCustomizer={this.props.renderCellCustomizer} actions={{ showAddButton: this.props.actions?.showAddButton, disableAddButton: this.props.actions?.disableAddButton }}
                    referenceEntityForAdd={this.entityTableSimpleCustomizedRef.current?.getEntities()[0]} openFormLight={this.openFormLight} onAdd={this.props.onAdd} getDefaultValuesForFields={this.props.getDefaultValuesForFields}
                    data-testid={this.props["data-testid"]} onSelectItem={this.props.onSelectItem} />
            </div>
            <EntityFormLight id={uniqueId("entityFormLight-")} ref={this.entityFormLightRef} headerContent={this.props.formCustomizer?.headerContent} headerIcon={this.props.formCustomizer?.headerIcon}
                refEditor={this.refEditor} onSubmit={(params: any) => {
                    if (this.props.formCustomizer?.mandatoryFields) {
                        for (let i = 0; i < this.props.formCustomizer.mandatoryFields.length; i++) {
                            let mandatoryField = this.props.formCustomizer.mandatoryFields[i];
                            const value = Utils.navigate(params.values, mandatoryField.split("."), false);
                            if (value == null || String(value).trim() === "") {
                                let message = _msg("validationMessage.mandatory.label", _msg(this.props.entityDescriptor.name + ".label"), _msg(mandatoryField + ".label").toLowerCase());
                                this.props.dispatchers.setInReduxState({ validationMessage: message });
                                params.clientValidationError = true;
                                return;
                            }
                        }
                    }
                    //doNotAddEntityToTable was used because there are cases when a verification is needed after pressing on "Save" button from form and before adding the data to table
                    !this.props.actions?.doNotAddEntityToTable && this.onAddOrUpdate(params.values, params.rowIndex);
                    this.props.onSave && this.props.onSave(this.entityTableSimpleCustomizedRef.current?.getEntities(), params);
                }} entityDescriptor={editorEntityDescriptor}
                saveDisabled={this.props.actions?.saveFormDisabled} modalClassName={this.props.formCustomizer?.modalClassName} />
        </>)
    }

    componentWillUnmount() {
        // used to overpass an error that occurs when the same slice is used for 2 or more different tables (different descriptors).
        // when opening the second table in modal, at first render, the columnConfigDropdown still had the columns of first table.
        this.props.dispatchers.columnConfigDropdown.setInReduxState({ columnConfig: undefined });
        if (!this.props.useDefaultColumnsWidths) {
            window.removeEventListener('resize', this.setEqualColumnsWidth);
        }
    }

    setEqualColumnsWidth = () => {
        let defaultCC = this.props.entityDescriptor.getDefaultColumnConfig();
        let elements = document.getElementsByClassName("EntityTableLight") || [];
        let row = undefined;
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.getAttribute("id") === this.props.entityDescriptor.name) {
                row = element;
                break;
            }
        }

        if (row && defaultCC.configObject?.columns?.length && defaultCC.configObject?.columns?.length > 1) {
            // get the width of the parent element and substract the width of the context menu column + additional 20 for scroll and column reorder container.
            // divide it to the total number of columns, so all the columns will have equal width.
            let cellWidth = (row.clientWidth - CONTENT_MENU_COLUMN_WIDTH - 20) / defaultCC.configObject?.columns?.length;
            if (cellWidth < COLUMN_DEFAULT_WIDTH) {
                cellWidth = COLUMN_DEFAULT_WIDTH;
            }
            let columns = [...defaultCC.configObject.columns];
            for (let i = 0; i < columns.length; i++) {
                columns[i] = { ...columns[i], width: cellWidth };
            }
            defaultCC = { ...defaultCC, configObject: { ...defaultCC.configObject, columns } };
            this.props.dispatchers.columnConfigDropdown.updateColumnConfig(defaultCC);
        }
    }

    protected setEqualColumnsWidthListener() {
        if (!this.props.useDefaultColumnsWidths) {
            window.addEventListener('resize', this.setEqualColumnsWidth, true);
            this.setEqualColumnsWidth();
        }
    }

    componentDidMount() {
        this.setEqualColumnsWidthListener();
    }

    protected onModalCancel = () => {
        this.props.dispatchers.onCancel();
        this.props.modalCustomizer?.onModalCancel?.();
    }

    render() {
        if (this.props.formCustomizer?.showInModal) {
            return (<div>
                <Modal size="large" className={this.props.formCustomizer?.modalClassName} closeIcon closeOnDimmerClick={false} open={this.props.modalOpen} onClose={this.onModalCancel}>
                    <Header icon={this.props.formCustomizer.headerIcon || "file"} content={this.props.formCustomizer.headerContent || this.props.entityDescriptor.name} />
                    <Modal.Content className="EntityTableLight_modal">
                        {this.getMainContent()}
                        {this.props.modalCustomizer?.additionalModalContent?.()}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => {
                            this.props.dispatchers.onCancel();
                            if (this.entityTableSimpleCustomizedRef.current) {
                                this.props.modalCustomizer?.onModalOk?.(this.entityTableSimpleCustomizedRef.current?.getEntities());
                            }
                        }}>
                            {_msg("general.ok")}
                        </Button>
                        <Button onClick={this.onModalCancel}>
                            {_msg("general.cancel")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>)
        } else {
            return this.getMainContent();
        }
    }
}

type EntityTableSimpleCustomizedProps = EntityTableSimpleProps & {
    renderCellCustomizer?: (props: CellProps) => void;
    actions?: { showAddButton?: boolean, disableAddButton?: boolean }
    onAdd?: (val?: any, getDefaultValuesForFields?: () => { [key: string]: any }) => any;
    // this property is currently used only for history table, where "fromDate" must be calculated based on "untilDate" value of entity from the first row
    referenceEntityForAdd: any;
    getDefaultValuesForFields?: () => { [key: string]: any };
    openFormLight: (entity: any, rowIndex: number) => void;
    "data-testid"?: string;
}

export class EntityTableSimpleCustomizedRaw extends EntityTableSimple<EntityTableSimpleCustomizedProps> {

    protected renderCell(props: CellProps) {
        this.props.renderCellCustomizer?.call(null, props);
        return super.renderCell(props);
    }

    protected openFormOnAdd(entity: any) {
        this.props.openFormLight(entity, NEW_ENTITY_INDEX);
    }

    protected getContextMenuColumn(): ReactNode {
        let col = super.getContextMenuColumn();
        return React.cloneElement(col as ReactElement, {
            // add "add button" inside the header of the table
            header: (props: any) => {
                return <Cell>
                    {(this.props.actions?.showAddButton === true || this.props.actions?.showAddButton === undefined) &&
                        <Button data-testid={this.props["data-testid"] + "_addButton"} floated="left" disabled={this.props.actions?.disableAddButton === true} icon={"add"} type="button" color="green"
                            onClick={() => {
                                this.props.onAdd ? this.openFormOnAdd(this.props.onAdd?.(this.props.referenceEntityForAdd, this.props.getDefaultValuesForFields)) : this.openFormOnAdd(undefined);
                            }}
                        />
                    }
                </Cell>
            }
        });
    }
}

export const EntityTableSimpleCustomized = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, EntityTableSimpleCustomizedRaw);"../../components/ColumnConfig/ColumnConfigDropdown""../../components/ColumnConfig/dataStructures""../../components/ModalExt/ModalExt""../../reduxReusableComponents/ReduxReusableComponents"