import { createSliceFoundation, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { Upload } from 'antd';
import { UploadChangeParam, UploadProps } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { InboxOutlined } from '@ant-design/icons';
import { ModalExt, Severity } from "../ModalExt/ModalExt";

export const sliceFileUploadButton = createSliceFoundation(class SliceFileUploadButton {

    initialState = {
        message: "",
        uploading: false
    }

    reducers = {
        ...getBaseReducers<SliceFileUploadButton>(this),
    }

});

type Props = PropsFrom<typeof sliceFileUploadButton> & UploadProps & {
    onUploadFiles: (files: UploadFile[]) => Promise<string | undefined>;
    disableUploadButton?: boolean;
    useDragAndDrop?: boolean;
    useUploadAsMenuItem?: boolean;
}

export class FileUploadButton extends React.Component<Props> {

    static defaultProps = {
        multiple: true,
        showUploadList: false,
        disableUploadButton: false,
        useDragAndDrop: false,
        useUploadAsMenuItem: false
    }

    state = {
        fileList: [] as any[]
    };

    onChange = (info: UploadChangeParam) => {
        if (info.file.status !== undefined) {
            return;
        }
        let fileList = [...info.fileList];

        if (!this.props.multiple) {
            // 1. Limit the number of uploaded files (1)
            fileList = fileList.slice(-1);
        }

        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url:link
                file.url = file.response.url;
            }
            return file;
        });

        this.setState({ fileList });
        this.props.dispatchers.setInReduxState({ uploading: true });
        (async () => {
            this.props.dispatchers.setInReduxState({message: await this.props.onUploadFiles(fileList), uploading: false});
          })()
    };

    render() {
        return (
            <div className="FileUploadButton_div">
                {!this.props.useDragAndDrop &&
                    <Upload {...this.props} onChange={this.onChange} beforeUpload={() => false} className={"FileUploadButton_upload"} disabled={this.props.disableUploadButton}>
                        {!this.props.useUploadAsMenuItem && <Button className="FileUploadButton_button" primary disabled={this.props.disableUploadButton}>
                            <Icon className="FileUploadButton_icon" loading={this.props.uploading === true} name={this.props.uploading === true ? 'spinner' : 'upload'} />
                            {this.props.uploading === true ? _msg("FileImporter.spinner") : _msg("dto_crud.import")}
                        </Button>}
                        {this.props.useUploadAsMenuItem &&
                            <Icon loading={this.props.message !== ""} name={this.props.message !== "" ? 'spinner' : 'upload'} />
                        }
                    </Upload>}
                {this.props.useDragAndDrop &&
                    <Dragger {...this.props} onChange={this.onChange} beforeUpload={() => false} disabled={this.props.disableUploadButton}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{_msg("FileUploadButton.clickOrDrag")}</p>
                    </Dragger>}
                <ModalExt severity={Severity.INFO} open={this.props.message.length > 0} closeIcon onClose={() => { this.props.dispatchers.setInReduxState({ message: "" }) }}>
                    <Modal.Header>{_msg("FileImporter.results")}</Modal.Header>
                    <Modal.Content >{this.props.message}</Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => this.props.dispatchers.setInReduxState({ message: "" })}>{_msg("general.ok")}</Button>
                    </Modal.Actions>
                </ModalExt>
            </div>
        );
    }

    componentWillUnmount(){
       this.props.dispatchers.setInReduxState({ message: ""});
    }
}
"../../reduxHelpers"